/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import axios from 'axios'
import LoginView from '../views/LoginView.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    meta: { requireAuth: 0, DeptCode: "" },
    component: LoginView,
  },
  {
    path: '/home',
    name: 'Home',
    meta: { requireAuth: 1, DeptCode: "", role_id: 0 },
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/jobendorsementGOorPA',
    name: 'jobendorsementGOorPA',
    meta: { requireAuth: 1, DeptCode: "", role_id: 12 },
    component: () => import('../views/JobEndorsement/JobEndorsementGOorPA.vue')
  },
  {
    path: '/jobendorsementHR',
    name: 'jobendorsementHR',
    meta: { requireAuth: 1, DeptCode: "", role_id: 13 },
    component: () => import('../views/JobEndorsement/JobEndorsementGOorPA.vue')
  },
  {
    path: '/jobendorsement',
    name: 'jobendorsement',
    meta: { requireAuth: 1, DeptCode: "", role_id: 14 },
    component: () => import('../views/JobEndorsement/JobEndorsementGOorPA.vue')
  },
/*
  {
    path: '/about',
    name: 'about',
    meta: { requireAuth: 1, DeptCode: "" },
    component: () => import('../views/AboutView.vue')
  },
  */
  {
    path: '/itequipmentrepair',
    name: 'ITRepair',
    meta: { requireAuth: 1, DeptCode: "", role_id: 1 },
    component: () => import('../views/ICTRepair/ITrepairView.vue')
  },
  {
    path: '/RepairQueue',
    name: 'RepairQueue',
    meta: { requireAuth: 1, DeptCode: "", role_id: 2 },
    component: () => import('../views/ICTRepair/RepairQueue.vue')
  },
  {
    path: '/ICTTechAssistance',
    name: 'ICTTechAssistance',
    meta: { requireAuth: 1, DeptCode: "", role_id: 16 },
    component: () => import('../views/ICTRepair/ICTTechAssistance.vue')
  },
  {
    path: '/TrackITRepairs',
    name: 'TrackITRepairs',
    meta: { requireAuth: 0, DeptCode: "", role_id: 0 },
    component: () => import('../views/ICTRepair/TrackITRepairs.vue')
  },
  {
    path: '/ZoomRequest',
    name: 'ZoomRequest',
    meta: { requireAuth: 1, DeptCode: "", role_id: 3 },
    component: () => import('../views/ZoomMeetingRequest/ZoomRequest.vue')
  },
  {
    path: '/ChangePassword',
    name: 'ChangePassword',
    meta: { requireAuth: 1, DeptCode: "", role_id: 0 },
    component: () => import('../views/ChangePassword.vue')
  },
  {
    path: '/TelephoneDirectory',
    name: 'TelephoneDirectory',
    meta: { requireAuth: 1, DeptCode: "", role_id: 4 }, 
    component: () => import('../views/TelephoneDirectory/TelephoneDirectory.vue')
  },
  {
    path: '/ICTequipmentStandardSpecs',
    name: 'ICTequipmentStandardSpecs',
    meta: { requireAuth: 1, DeptCode: "", role_id: 5 },
    component: () => import('../views/StandardSpecification/ICTequipmentStandardSpecs.vue')
  },
  {
    path: '/PRInstructions',
    name: 'PRInstructions',
    meta: { requireAuth: 1, DeptCode: "", role_id: 5 },
    component: () => import('../views/PRForms/PRInstructions.vue')
  },
  {
    path: '/PRs',
    name: 'PRs',
    meta: { requireAuth: 1, DeptCode: "", role_id: 5 },
    component: () => import('../views/PRForms/PRs.vue')
  },
  {
    path: '/PRForm',
    name: 'PRForm',
    meta: { requireAuth: 1, DeptCode: "", role_id: 5 },
    component: () => import('../views/PRForms/PRForm.vue')
  },
  {
    path: '/GeneralService',
    name: 'GeneralService',
    meta: { requireAuth: 1, DeptCode: "", role_id: 6 },
    component: () => import('../views/GeneralService.vue')
  },
  /*
  {
    path: '/MyFiles',
    name: 'MyFiles',
    meta: { requireAuth: 1, DeptCode: "", role_id: 0 },
    component: () => import('../views/Archiving/MyFiles.vue')
  },
  {
    path: '/ViewFiles/:subject_id',
    name: 'ViewFolder',
    meta: { requireAuth: 1, DeptCode: "", role_id: 0 },
    component: () => import('../views/Archiving/ViewFolder.vue')
  },
  {
    path: '/Shared',
    name: 'Shared',
    meta: { requireAuth: 1, DeptCode: "", role_id: 0 },
    component: () => import('../views/Archiving/Shared.vue')
  },
  */

]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  

    store.dispatch('hasAuthorization')
    .then((resp) => {
      let roles = resp.data[0].roles;
      if(to.meta.role_id > 0){
        to.meta.DeptCode = resp.data[0].DeptCode;
        if(roles.filter(r => r.role_id == to.meta.role_id)[0].permissions == 1){
          next();
        }else{
          next({ name: 'Home' });
        }
        
      }else{
        if (to.name !== 'Login' && to.meta.requireAuth == 1) {
          to.meta.DeptCode = resp.data[0].DeptCode;
          next();
        }else{
          next({ name: 'Home' });
        }
      }
     
      
    })
    .catch((err) => {
      if (to.name !== 'Login' && to.meta.requireAuth == 1) {
        next({ name: 'Login' });
      }
      next();
    })
 
  
})
 

export default router


